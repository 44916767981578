<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-form-crear-tepTurno"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Validar Turno</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="vehiculo">Vehiculo</label>
                  <v-select
                    :class="[
                      $v.form.vehiculo_id.$invalid ? 'is-invalid' : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="vehiculo"
                    placeholder="Placa"
                    label="placa"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.vehiculos"
                    :filterable="false"
                    @search="buscarVehiculos"
                    @input="selectVehiculo()"
                  ></v-select>
                </div>

                <div class="col-md-12" v-if="form.vehiculo_id">
                  <div class="row">
                    <div class="form-group col-md-5">
                      <label for="fecha_inicial">Fecha</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        id="fecha_inicial"
                        placeholder="Fecha Inicial"
                        v-model="form.fecha_inicial"
                        :class="
                          $v.form.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div class="form-group col-md-5">
                      <label for="hora_inicial">Hora</label>
                      <input
                        type="text"
                        id="hora_inicial"
                        class="form-control form-control-sm"
                        v-model="form.hora_inicial"
                        :class="
                          $v.form.hora_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-success"
                  v-show="
                    !$v.form.vehiculo_id.$invalid &&
                      !$v.form.fecha_inicial.$invalid &&
                      !$v.form.hora_inicial.$invalid
                  "
                  v-if="$store.getters.can('tep.turnos.create')"
                  @click="validarProgrmacion()"
                >
                  <i class="fas fa-check-double col-md-12"></i>
                  <strong> Validar</strong>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="programaciones.length > 0">
            <table
              id="users"
              class="table table-bordered table-striped table-hover table-sm"
            >
              <thead>
                <tr>
                  <th>Postulacion</th>
                  <th>Fecha</th>
                  <th>Placa</th>
                  <th>Conductor</th>
                  <th>Seleccione...</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in programaciones" :key="item.id">
                  <td>
                    {{ item.id }}
                  </td>
                  <td>{{ item.fecha }}</td>
                  <td>{{ item.vehiculo.placa }}</td>
                  <td>
                    {{
                      item.conductor
                        ? item.conductor.nombres +
                          " " +
                          item.conductor.apellidos
                        : ""
                    }}
                  </td>
                  <td>
                    <a
                      id="botonImp"
                      type="button"
                      class="dropdown-item"
                      style="cursor: pointer"
                      @click="selectprogramacion(item)"
                    >
                      <i class="fas fa-flag-checkered"></i>
                      Crear turno
                    </a>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
import vSelect from "vue-select";
export default {
  name: "TepTurnoCrear",
  components: {
    vSelect,
  },
  data() {
    return {
      flag: null,
      form: {
        vehiculo_id: null,
        fecha_inicial: null,
        hora_inicial: null,
      },
      vehiculo: [],
      turno: {},
      programaciones: {},
      listasForms: {
        vehiculos: [],
      },
      tipo_control: null,
    };
  },
  validations: {
    form: {
      vehiculo_id: {
        required,
      },
      fecha_inicial: {
        required,
      },
      hora_inicial: {
        required,
        timeFormat,
      },
    },
  },
  methods: {
    async llenar_modal() {},

    validarProgrmacion() {
      axios
        .get("/api/tep/turnos/validarVehiculo", {
          params: this.form,
        })
        .then((response) => {
          this.turno = response.data;
          if (this.turno.mensaje) {
            this.$swal({
              icon: "error",
              title: this.turno.mensaje,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 8000,
              timerProgressBar: true,
            });
            this.programaciones = {};
          } else if (this.turno.auth == 1) {
            this.programaciones = this.turno.postulacion;
          }
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectprogramacion(form) {
      this.$swal({
        title: "Se encontro postulacion para el dia " + form.fecha,
        html: `Conductor: ${form.conductor ? form.conductor.nombres : ""} ${
          form.conductor ? form.conductor.apellidos : ""
        } <br>
                Vehiculo: ${form.vehiculo.placa} <br>
                
                <b>Desea crear un turno para esta postulacion?<b>`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Si, Crear!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$swal({
            title: "Está seguro de crear el Turno ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Crear!",
            cancelButtonText: "Cancelar",
          }).then((result) => {
            if (result.value) {
              let form_turno = {
                sitio_id: form.tep_nominacion.sitio_id,
                vehiculo_id: form.vehiculo.id,
                conductor_id: form.conductor ? form.conductor.id : null,
                det_postulacion_id: form.id,
                estado: 3,
                fecha: this.form.fecha_inicial,
                fecha_t: new Date(
                  this.form.fecha_inicial + " " + this.form.hora_inicial
                ),
                empresa_id: form.tep_det_nominacion.empresa_id,
                tipo_vehiculo_id: form.vehiculo.tipo_vehiculo_id,
                area: form.tep_det_nominacion.area,
                turno_id: form.tep_det_nominacion.turno_id,
                tipo_operacion: form.tep_det_nominacion.tipo_operacion,
              };
              this.crearTurno(form_turno);
              this.$refs.closeModal.click();
              this.$parent.getIndex();
            }
          });
        }
      });
    },

    crearTurno(form) {
      axios({
        method: "POST",
        url: "/api/tep/turnos/crearturno",
        data: form,
      })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "El turno se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.programaciones = {};
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarVehiculos(search) {
      let me = this;
      me.listasForms.vehiculos = [];
      if (search != "") {
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url, {})
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectVehiculo() {
      this.form.vehiculo_id = "";
      if (this.vehiculo) {
        this.form.vehiculo_id = this.vehiculo.id;
      }
    },
  },
};
</script>
